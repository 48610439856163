import React, { useEffect, useState } from "react"; 
import Event from "./components/UI/Event";
import hero from "./assets/svg/KALENDAR.webp";
import { Helmet } from 'react-helmet-async';
import Layout from "./Layout";
import axios from "axios";
import { APIURL } from "./index";
import Loading from "./components/UI/Loading";

const EventsPage = () => {
    const [active, setActive] = useState('all');
    const [loading, setLoading] = useState(true); 
    const prices = [{label:"Vše", value:"all"},{label:"Zdarma", value:"false"},{label:"Vstupné", value:"true"}]
    const [archiv, setArchiv] = useState(false);
    const [page, setPage] = useState(1);  

    const getCurrentDate = () => { 
        var now = new Date();
        var currentMonth = now.getMonth()+1;
        if (currentMonth < 10) { currentMonth = '0' + currentMonth; };
        return now.getFullYear()+"-"+currentMonth+"-"+now.getDate()
    }

    const [eventsData, setEventsData] = useState();

    const getEvents = async () => {
        setLoading(true);
        let data = {
            type: 'events',
            language: 'cs',
            orderAsc: "ASC",
            limit: 10,
            page: page,
            filter: [{name:"date", action:archiv?"<":">", value:getCurrentDate()}]
        }
        if (active!='all') {
            data.filter.push({ name: "pricing", value: active })
        }
        await axios.post(APIURL + "/events", data).then((response) => {
            setEventsData(response.data.body); 
            setLoading(false);
        });
    };

    useEffect(() => {
        setPage(1);
        getEvents();
    }, [active, archiv]);

    useEffect(() => {
        getEvents();
    }, [page]);

    return (
        <Layout nested={1}>
            <Helmet>
                <title>Události | Nehoda nanečisto</title>
                <meta
                    name="description"
                    content="Navštivte náš stánek na různých akcích po České republice a zažijte dopravní nehodu nanečisto."
                />

                <meta property="og:title" content="Události" />
                <meta
                    property="og:description"
                    content="Navštivte náš stánek na různých akcích po České republice a zažijte dopravní nehodu nanečisto."
                />
                <meta property="og:site_name" content="Nehoda nanečisto" />

                <meta name="twitter:title" content="Události" />
                <meta
                    name="twitter:description"
                    content="Navštivte náš stánek na různých akcích po České republice a zažijte dopravní nehodu nanečisto."
                />
            </Helmet>

            <div className="terminy">
                <div className="terminy__header">
                    <div className="terminy__header__title">
                        <h1>termíny UDÁLOSTÍ</h1>
                        <p>
                            Navštivte náš <b>modrý stánek Platforma VIZE 0 </b>{" "}
                            na některé z níže uvedených akcí a vyzkoušejte si na
                            vlastní kůži, jaké je to zažít nehodu, ale
                            nanečisto.
                        </p>
                    </div>
                    <div className="terminy__header__hero">
                        <img src={hero} alt="ilustrace kalendáře" />
                    </div>
                </div>
                <div className="terminy__box">
                    <div className="terminy__box__filter"> 
                        {prices?.map(p => (
                            <a
                                key={p.value}
                                className={active === p.value ? "active" : ""}
                                onClick={() => setActive(p.value)}
                            >
                                {p.label}
                            </a>
                        ))}
                        <span>|</span>
                        <a
                            className={'fancy '+(archiv?'active':'')}
                            onClick={() => {setArchiv(!archiv)}}
                        >
                            proběhlé
                        </a>
                    </div>
                    <div className="terminy__box__content" style={loading ? {gridTemplateColumns:"1fr"} : {}}>
                        {!loading ? (
                            eventsData?.events?.map((event, i) => (
                                <React.Fragment key={i}>
                                    <Event event={event} />
                                </React.Fragment>
                            ))
                        ) : (
                            <Loading />
                        )}
                    </div>
                    {!loading&&eventsData?.pages>1&&<div className="terminy__box__content__pagination">
                            {Array(eventsData.pages).fill().map((_, i) => <a key={i} className={page===1 + i?'active':''} onClick={()=>setPage(1 + i)}>{1 + i}</a>)}
                        </div> }
                </div>
            </div>
        </Layout>
    );
};

export default EventsPage;
