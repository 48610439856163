import { Car, PersonSimpleWalk, SealCheck } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const Item = ({data}) => {
    return (
        <div className={"elearning_question "+(data.pov.includes('chodce')?'chodec':'ridic')}  style={data.img&&{backgroundImage:`url(https://nehodananecisto.cz${data.img})`}}>
            <span>{data.pov.includes('chodce')?<PersonSimpleWalk />:<Car /> } {data.pov}</span>
            <h3>{ data.name}</h3> 
            <a href={"https://vyzkousej.nehodananecisto.cz/"+data.url+"-"+data.id_group} target='_blank' >zahrát &rsaquo;</a>
        </div>
    )
}

export default Item;