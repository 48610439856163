import React, { useEffect, useState } from "react"; 
import axios from "axios";
import { APIURL } from "../..";
import { Link } from "react-router-dom";
import Item from "../Item";

const Elearning = () => { 
    
const [allQuizes, setAllQuizes] = useState([]);

    const fetch = async () => {
        await axios.post(APIURL +'/collection/items', {type:'quiz', language: 'cs', orderAsc: "ASC", limit: 100, page:1}).then((response=>{
            setAllQuizes(response.data.body);
        }))
    }

    useEffect(() => {
        fetch();
    }, []);
    return (
        <>
            <section className="elearning events" id="elearning">
                <div className="elearning__box">
                    <div className="perex">
                        <span>E-learning</span>
                        <h2>NEMŮŽEŠ ZKUSIT VR OSOBNĚ? </h2>
                        <p>
                            Nebyla možnost navštívit nějakou z našich akcí a nemáš vlastní VR brýle? Vyzkoušej naše situace přímo na své obrazovce.
                        </p>
                    </div>

                    <div className="elearning__box__container">
                        {/* <span className="alert">Připravujeme</span> */}
                        {allQuizes?.items?.map(q=> <Item data={q} /> )}
                    </div>
                    <a
                        href="https://vyzkousej.nehodananecisto.cz/"
                        className="button transBlue"
                        target="_blank"
                        style={{ margin: "auto" }}
                    >
                        další moduly
                    </a>
                </div>
            </section>
        </>
    );
};

export default Elearning;
