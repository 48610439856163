import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../App";
import axios from "axios";
import { APIURL } from "../..";

const Lokality = () => {
    const [lokalityData, setLokalityData] = useState({}); 
    const getLokality = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: 'tried',
                orderAsc: "ASC", 
                limit: 100, 
                page: 1,
                language: 'cs'
            })
            .then((response) => { 
                setLokalityData(response.data.body);
            });
    };

    useEffect(() => {
        getLokality();
    }, []);
    return (
        <>
            <section className="lokality" id="lokality">
                <div className="lokality__box">
                    <div className="perex">
                        <span>Navštívili jsme</span>
                        <h2>Kde všude jsme byli?</h2>
                        <p> Jako putovní projekt navštěvujeme veřejné i soukromé akce. Pořádáme školení pro firmy, návštěvy v autoškolách a akce pro základní a střední školy. Místa a společnosti, které jsme již navštívili, můžete vidět níže. </p>
                    </div> 

                    <div className="firmy"> 
                        {lokalityData?.items?.map((lokalita, i) => (
                            <React.Fragment key={i}>
                                <a href={lokalita.link} target="_blank"><img className="logo" src={"https://nehodananecisto.cz/"+lokalita.logo} alt={lokalita.alt} /></a>
                            </React.Fragment>
                        ))} 
                    </div>                   
                    
                    <a
                        href="https://en.mapy.cz/s/fepegezumu"
                        className="button transBlue"
                        target="_blank"
                        style={{ margin: "auto" }}
                    >
                        mapa navštívených míst
                    </a> 
                </div>
            </section>
        </>
    );
};

export default Lokality;
