import { CaretRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const Event = ({ event }) => {
    //Geting date from unformatted date
    const getDateFormat = (input) => {
        var newDate_from = new Date(input.date); 

        const f_yyyy = newDate_from.getFullYear();
        let f_mm = ("0" + (newDate_from.getMonth() + 1)).slice(-2);
        let f_dd = ("0" + newDate_from.getDate()).slice(-2);

        if (input.date_to) {
            var newDate_to = new Date(input.date_to); 
            const t_yyyy = newDate_to.getFullYear();
            let t_mm = ("0" + (newDate_to.getMonth() + 1)).slice(-2);
            let t_dd = ("0" + newDate_to.getDate()).slice(-2);

            let m = f_mm < t_mm ? ". " + f_mm : "";
            return f_dd + m+ ". " + " - " + t_dd + ". " + t_mm + ". " + t_yyyy;
        } else {
            return f_dd + ". " + f_mm + ". " + f_yyyy;
        }
    };

    const getWrapper= (url, children) => {
        return url? <Link target="_blank" to={url}>{children}</Link> : <a>{children}</a>
    }

    return getWrapper(event.url, <div className="event">
    <div className="event__header">
        <span>{getDateFormat(event)}</span>
    </div>
    <div className="event__body">
        <h2>{event.name}</h2>
        <span>{event.place}</span>
    </div>
    <div className="event__footer">
        <span>
            {event.pricing == "true" ? "vstupné" : "zdarma"}
        </span>
        {event.url && (
            <a href={event.url} target="_blank" className="button transparent">
                Více <CaretRight size={"24"} />
            </a>
        )}
    </div>
</div>);
};

export default Event;
